import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout.js"
import Seo from "../components/seo"
import Latest from "../components/latest"
import Rightcol from "../components/rightcol"

const Manga = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Manga List" />
      <div className="flex-grow lg:flex border-t-4 border-indigo-500 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto ">
        <div className="min-w-0 flex-1 bg-white xl:flex">
         
          <div className="bg-transparent lg:min-w-0 lg:flex-1">
            <div className="border-b border-t border-gray-200 p-2 xl:border-t-0">
              <div className="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                </svg>

                <h1 className="text-lg font-medium pl-2">MANGA LIST</h1>
              </div>
            </div>

            <div className="pl-2 pr-2 pt-4 pb-4   xl:border-t-0  xl:pt-6" isHomePage>
              <div className="flex items-center">
              <Latest/>
              </div>
            </div>
          </div>
        </div>
        <Rightcol/>
      </div>
    </Layout>
  )
}

export default Manga

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
